import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import img1 from '../../assets/phone/1.png';
import oneGraphIgm from '../../assets/phone/oneGraph.png';
import twoGraphIgm from '../../assets/phone/2Graph.png';
import setsImg from '../../assets/phone/sets.png';
import editImg from '../../assets/phone/edit.png';


import { styled } from '@mui/material/styles';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Progress at a glance',
    description:"Gain valuable insights into your fitness journey with WorkoutStats' intuitive progress analysis tools, allowing you to track your performance and achieve your goals with precision.",
   imageLight: `url(${oneGraphIgm})`,
    imageDark:  `url(${twoGraphIgm})`,
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Quick Inputs, Quicker Gains',
    description:
      "Customize your workout experience to fit your unique needs with WorkoutStats' customizable features, empowering you to tailor your routine and take control of your fitness.",
      imageLight: `url(${setsImg})`,
      imageDark:  `url(${setsImg})`,
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Easy Workout Tracking',
    description:
      'Simplify your fitness journey with WorkoutStats – the easy-to-use app designed to streamline workout tracking and maximize your progress.',
      imageLight: `url(${editImg})`,
      imageDark:  `url(${editImg})`,
  },
];

const Chip = styled(MuiChip)(({ theme, selected }) => ({
  ...(selected && {
    borderColor:
      theme.palette.mode === 'light'
        ? theme.palette.primary.light
        : theme.palette.primary.dark,
    background:
      'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
    color: 'hsl(0, 0%, 100%)',
    '& .MuiChip-label': {
      color: 'hsl(0, 0%, 100%)',
    },
  }),
}));

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
               Features
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
             WorkoutStats revolutionizes fitness tracking with its intuitive interface, enabling users to effortlessly record workout details like sets, reps, and weights. Dive into insightful graphs to compare progress over time, and easily export/import data for seamless management. Make every workout count with WorkoutStats, your ultimate fitness companion.
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                selected={selectedItemIndex === index}
              />
            ))}
          </Grid>
          <Card
            variant="outlined"
            sx={{
              borderRadius: 4,

              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: 4,

                backgroundPosition: 'center',
                minHeight: 980,
              }}
            />
            <Box sx={{ px: 2, pb: 2, mt:2 }}>
              <Typography color="text.primary" fontWeight="medium" gutterBottom>
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ mb: 1.5 }}>
                {selectedFeature.description}
              </Typography>
              {/* <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link> */}
            </Box>
          </Card>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={(theme) => ({
                  p: 2,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  ...(selectedItemIndex === index && {
                    backgroundColor: 'action.selected',
                    borderColor:
                      theme.palette.mode === 'light'
                        ? 'primary.light'
                        : 'primary.dark',
                  }),
                  '&:hover': {
                    background:
                      theme.palette.mode === 'light'
                        ? 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)'
                        : 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                    borderColor:
                      theme.palette.mode === 'light'
                        ? 'primary.light'
                        : 'primary.dark',
                    boxShadow:
                      theme.palette.mode === 'light'
                        ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                        : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                  },
                })}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      color:
                        theme.palette.mode === 'light' ? 'grey.400' : 'grey.600',
                      ...(selectedItemIndex === index && {
                        color: 'primary.main',
                      }),
                    })}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography
                      color="text.primary"
                      fontWeight="medium"
                      gutterBottom
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ mb: 1.5 }}
                    >
                      {description}
                    </Typography>
                    {/* <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link> */}
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              // height: 'auto%',
              // width: '100%',
              height: 'fit-content',

              borderRadius: 2,
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 230,
                height: 500,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                borderRadius: 2,
                border: '1px solid',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
