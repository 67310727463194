import React, {useEffect, useState} from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import StackProgress from "./skillProgress/skillProgress";
import WorkExperience from "./workExperience/WorkExperience";
import Projects from "./projects/Projects";
import StartupProject from "./StartupProjects/StartupProject";
import Achievement from "./achievement/Achievement";
import Blogs from "./blogs/Blogs";
import Footer from "../components/footer/Footer";
import Talks from "./talks/Talks";
import Podcast from "./podcast/Podcast";
import Education from "./education/Education";
import ScrollToTopButton from "./topbutton/Top";
import Twitter from "./twitter-embed/twitter";
import Profile from "./profile/Profile";
import SplashScreen from "./splashScreen/SplashScreen";
import {splashScreen} from "../portfolio";
import {StyleProvider} from "../contexts/StyleContext";
import {useLocalStorage} from "../hooks/useLocalStorage";
import Ably from  'ably';
import "./Main.scss";

const Main = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] =
    useState(true);


    async function publishSubscribe() {
      // Connect to Ably with your API key
      const ably = new Ably.Realtime("U8tHqw.u-PgrA:rLrWyV-EsIRloZe4fiAyNWPFbA0oo4dzVz3_r6U5wRY")
      ably.connection.once("connected", () => {
        console.log("Connected to Ably!")
      })
    
      // Create a channel called 'get-started' and register a listener to subscribe to all messages with the name 'first'
      const channel = ably.channels.get("get-started")
      await channel.subscribe("first", (message) => {
        console.log("Message received: " + message.data)
      });
    
      // Publish a message with the name 'first' and the contents 'Here is my first message!'
      await channel.publish("first", "Here is my first message!")
    
      // Close the connection to Ably after a 5 second delay
      setTimeout(async () => {
        ably.connection.close();
          await ably.connection.once("closed", function () {
            console.log("Closed the connection to Ably.")
          });
      }, 5000);
    }
    
     publishSubscribe();

     useEffect(() => {
// i was savinf views in database but i have to remove it
  //   //api to count how many times website is view

  //   const graphqlQuery = {
  //     query: `
  //   query {
  //     portfolio {
  //       message
  //     }
  //   }
  //   `
  //   };
  //   fetch("http://localhost:8080/graphql", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(graphqlQuery)
  //   })
  //     .then(res => {
  //       console.log("response from api views", res);
  //       return res.json();
  //     })
  //     .then(resData => {
  //       if (resData.errors) {
  //         throw new Error("Fetching posts failed!");
  //       }
  //     });

  //   // end of api

    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
        {isShowingSplashAnimation && splashScreen.enabled ? (
          <SplashScreen />
        ) : (
          <>
            <Header />
            <Greeting />
            <Skills />
            <StackProgress />
            <Education />
            <WorkExperience />
            <Projects />
            <StartupProject />
            <Talks />
            <Achievement />
            <Blogs />
            <Twitter />
            <Podcast />
            <Profile />
            <Footer />
            <ScrollToTopButton />
          </>
        )}
      </StyleProvider>
    </div>
  );
};

export default Main;
