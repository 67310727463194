import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
    createHashRouter,
    RouterProvider
  } from 'react-router-dom';
  import LandingPage from "./workoutStats/Containers/LandingPage/LandingPage.js";

// ReactDOM.render(<App />, document.getElementById("root"));

// ReactDOM.render(  <App />, document.getElementById('root'));

const router = createHashRouter([
    {
      path: "/",
      element: <App />,
    },
    {
      path: "workoutstats",
      element: <LandingPage />,
    },
    {
      path: "/workoutstats/support",
      element: <LandingPage />,
    },
    {
      path: "/workoutstats/privacy",
      element: <LandingPage isPrivacy={true} />,
    },
  
  
  ]);

  ReactDOM.render(    <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
