import React, {useContext} from "react";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson.json";
import workingout from "../../assets/lottie/workingout.json";

import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import mobile from  "../../assets/lottie/mobile.json";

import {illustration, greeting} from "../../portfolio";
import * as appFolio from "../../appfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting(props) {
  const {isDark} = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }


  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {" "}
                {greeting.title}{" "}
                <span className="wave-emoji">{emoji("👋")}</span>
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {props?.isWorkoutStats ?appFolio.greeting.subTitle : greeting.subTitle}
              </p>
              {!props?.isWorkoutStats &&  <SocialMedia />}
             
              {props?.isWorkoutStats ? 
              <div className="button-greeting-div">

                <Button
                  text="Download my app"
                  newTab={true}
                  href={"https://apps.apple.com/us/app/wokoutstats/id6478128385"}
                />

            </div>
               :
              <div className="button-greeting-div">
              <Button text="Contact me" href="#contact" />
              {greeting.resumeLink && (
                <Button
                  text="See my resume"
                  newTab={true}
                  href={greeting.resumeLink}
                />
              )}
            </div>
              }
              
            </div>
          </div>
          <div className="greeting-image-div">
            {illustration.animated ? (
              <>
              {props?.isWorkoutStats ? <DisplayLottie animationData={workingout} /> :
                <DisplayLottie animationData={landingPerson} />
              }
              </>

            ) : (
              <img
                alt="man sitting on table"
                src={require("../../assets/images/manOnTable.svg")}
              ></img>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
