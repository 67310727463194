//Landing page for my mobile app
import React, {useEffect, useState} from "react";
// import Header from "../components/header/Header";
// import Greeting from "./greeting/Greeting";

// import SplashScreen from "./splashScreen/SplashScreen";
// import {splashScreen} from "../portfolio";
// import {StyleProvider} from "../contexts/StyleContext";
 import styles from "./styles.css";
 import "../../../containers/Main.scss";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import Greeting from "../../../containers/greeting/Greeting";
import LandingPage2  from  "../../landing-page/LandingPage2";
import SplashScreen from "../../../containers/splashScreen/SplashScreen";
import {StyleProvider} from "../../../contexts/StyleContext";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {splashScreen} from "../../../portfolio";
const Hero = () => {
  return (

    <div className="hero">
      <div className="hero-content">
        <h1>Welcome to WokoutStats</h1>
        <p>Track your workouts and achieve your fitness goals with ease.</p>
      </div>
    </div>
  );
};

function Features() {
  return (
    <section className="features">
      <h2>Features</h2>
      <ul>
        <li>Track various types of workouts, including cardio, strength training, and yoga.</li>
        <li>Set personalized fitness goals and monitor your progress over time.</li>
        <li>View detailed statistics and analytics for each workout session.</li>
        <li>Sync your data across devices and access it anytime, anywhere.</li>
      </ul>
    </section>
  );
}


const LandingPage = (props) => {

  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] =
    useState(true);

    useEffect(() => {
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);

    const changeTheme = () => {
      setIsDark(!isDark);
    };
  return (
    <div >
<StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
 

             {/* <Header  isWorkoutStats={true}/>
             <Greeting isWorkoutStats={true} />


       <Footer /> */}
       <LandingPage2 isPrivacy={props?.isPrivacy}/>

     

       </StyleProvider>
   
    </div>
  );
};

export default LandingPage;
