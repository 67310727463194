import React from "react";
import "./TalkCard.scss";
import appImg from "../../workoutStats/assets/icon.png"

export default function TalkCard({talkDetails}) {
  return (
    <div>
      <div className="container">
        
        <div
          className={
            talkDetails.isDark ? "dark-rectangle rectangle" : "rectangle"
          }
        >
           
          <div className="diagonal-fill">
            
          </div>
        
          <div className="talk-card-title">

<div className="title-text">

            {talkDetails.title}
</div>

<div>

            <img
            src={appImg}
            alt="app"
            className="app-image"
            />
            </div>


          </div>
      
          <p className="talk-card-subtitle">{talkDetails.subtitle}</p>
          
          <div className="card-footer-button-div">
            

            <a href={talkDetails.slides_url} target="_" className="talk-button">
              Download
            </a>
            <a href={talkDetails.event_url} target="_" className="talk-button">
              Website
            </a>
         
          </div>
        </div>
      </div>
    </div>
  );
}
