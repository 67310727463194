import React from "react";
import "./App.scss";
import Main from "./containers/Main";
import {
  createHashRouter,
  RouterProvider
} from 'react-router-dom';
import LandingPage from "./workoutStats/Containers/LandingPage/LandingPage.js";

const App = () => {
  // return (
  //   <Router basename="/">
  //   <Routes>
  //     <Route path="/" element={<Main />} />
  //     <Route path="/workoutstats" element={<LandingPage />} />
  //     <Route path="/workoutstats/support" element={<LandingPage />} />
  //     <Route path="/workoutstats/privacy" element={<LandingPage isPrivacy={true}/>} />
  //   </Routes>
  // </Router>
  // );

  return <Main />;
};



export default App;
